import Swal from 'sweetalert2/dist/sweetalert2.js'
import style from './style.module.css'

export function formValidation(data) {
  let isValid = true;
  data.forEach(el => {
    if (el === "" || el === undefined || el === null) {
      isValid = false;
    }
  });
  if (isValid) {
    return true;
  } else {
    return false;
  }
}

export function SweetAlert(data) {

  var Toast = Swal.mixin({
    background: "rgb(0 47 105)",
    showConfirmButton: false,
    timerProgressBar: true,
    showCloseButton: true,
    color:"white",
    customClass: { icon: style.iconStyle, closeButton: style.closeButtonStyle, container: style.containerStyle },
    // loaderHtml: "<p> Hellow </p>",
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
      // Swal.showLoading()
    }
  });

  return Toast.fire(data)
}


