import React, { useEffect, useState, useContext } from 'react'
import { Alert, Button, Card, CardContent, CardHeader, Collapse, Container, Grid, IconButton, MenuItem } from '@mui/material'
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { api } from '../../Helper/Data'
import axios from 'axios';
import { getCookie } from '../../Helper/Cokkies'
import style from './style.module.css'
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { setIndexValue } from '../../Redux/pageIndex';
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { apiContext } from '../Login/ApiToggle';

const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));


function EditClient() {
  const dispatch = useDispatch()
  const location = useLocation()
  const [firmList, setFirmList] = useState();
  const [DataForm, setDataForm] = useState(location.state.params)
  const [otherType, setOtherType] = useState(false)
  const token = getCookie('token')
  const [isAlert, setIsAlert] = useState({ status: false, severity: "", message: "" })
  const api = useContext(apiContext)

  useEffect(() => {
    axios.post(api.url + '/firms-list', { id: "id" }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        setFirmList(response.data.f);
      })
  }, [])

  useEffect(() => {
    document.title = 'Edit Project | ICS';
    dispatch(setIndexValue(0))
  }, [dispatch])

  const changeHandler = (e) => {
    setDataForm({ ...DataForm, [e.target.name]: e.target.value })
  }

  const selectType = (e) => {
    e.target.value === 'Other' ? setOtherType(true) : setOtherType(false)
    setDataForm({ ...DataForm, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {
    setIsAlert({ ...isAlert, status: false, severity: "", message: "" })
    axios.post(api.url + '/edit-project', DataForm, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        setIsAlert({ ...isAlert, status: true, severity: "success", message: <>Project has been edit successful ! <Link to={"/projects-list"} style={{ textDecoration: "none" }}>Go to Project List</Link> </> })
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }

  const selectDate = (date) => {
    if (date != null) {
      const cDate = new Date();
      const date2 = new Date(moment(date._d).format('MM/DD/YYYY'));
      if (date2 - cDate < 0) {
        setIsAlert({ ...isAlert, status: true, severity: "error", message: <>Date should be more than today! </> })
      } else {
        setDataForm({ ...DataForm, reminderDate: date._d })
      }
    }
  }

  return (
    <Container maxWidth='lg'>
      <Card style={{ height: "100%", minHeight: "80vh" }}>
        <CardHeader style={{ textAlign: "center", color: "white" }} className={"main-bg"}
          title="Edit Project"
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <RedditTextField
                required
                select
                size='small'
                fullWidth
                name="firm"
                label="Firm"
                defaultValue={DataForm.firm}
                onChange={(e) => changeHandler(e)}
                id="firm"
                variant="filled"
                style={{ marginTop: 11 }}>
                <MenuItem key={""} value={""}>
                  Select
                </MenuItem>
                {firmList && firmList.map((item) => (
                  <MenuItem key={item.firm} value={item.firm}>
                    {item.firm}
                  </MenuItem>
                ))}
              </RedditTextField>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <RedditTextField
                required
                size='small'
                fullWidth
                name="projectName"
                label="Project Name"
                defaultValue={DataForm.projectName}
                onChange={(e) => changeHandler(e)}
                id="projectName"
                variant="filled"
                style={{ marginTop: 11 }} />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <RedditTextField
                required
                select
                size='small'
                fullWidth
                name="projectType"
                label="Project Type"
                defaultValue={DataForm.projectType}
                onChange={(e) => selectType(e)}
                id="projectType"
                variant="filled"
                style={{ marginTop: 11 }}>
                <MenuItem value={"Hosting"}>
                  Hosting
                </MenuItem>
                <MenuItem value={"Domain"}>
                  Domain
                </MenuItem>
                <MenuItem value={"AMP"}>
                  AMP
                </MenuItem>
                <MenuItem key={"Email"} value={"Email"}>
                  Email
                </MenuItem>
                <MenuItem value={"Birthday"}>
                  Birthday
                </MenuItem>
                <MenuItem value={"Aniversary"}>
                  Aniversary
                </MenuItem>
                <MenuItem value={"Other"}>
                  Other
                </MenuItem>
              </RedditTextField>
            </Grid>

            {otherType ? <Grid item md={6} sm={12} xs={12}>
              <RedditTextField
                required
                size='small'
                fullWidth
                name="projectType"
                label="Other Project Type"
                defaultValue={DataForm.projectType}
                onChange={(e) => changeHandler(e)}
                id="projectType"
                variant="filled"
                style={{ marginTop: 11 }} />
            </Grid> : ""}

            <Grid item md={6} sm={12} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  className={style.datePicker}
                  inputFormat="DD/MM/YYYY"
                  label="Date of Reminder"
                  value={DataForm.reminderDate}
                  onChange={(newValue) => {
                    selectDate(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <RedditTextField
                required
                select
                size='small'
                fullWidth
                name="validity"
                label="Validity"
                defaultValue={DataForm.validity}
                onChange={(e) => changeHandler(e)}
                id="validity"
                variant="filled"
                style={{ marginTop: 11 }}>
                <MenuItem value={"Yearly"}>
                  Yearly
                </MenuItem>
                <MenuItem value={"Monthly"}>
                  Monthly
                </MenuItem>
              </RedditTextField>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                fullWidth
                style={{ maxWidth: "400px", fontWeight: "bolder" }}
                variant="contained"
                className='ics-btn'
                onClick={(e) => handleSubmit()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={isAlert.status}>
              <Alert
                severity={isAlert.severity}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setIsAlert({ ...isAlert, status: false });
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {isAlert.message}
              </Alert>
            </Collapse>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}

export default EditClient