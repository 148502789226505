import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import { api } from '../../Helper/Data'
import { styled } from '@mui/material/styles';
import { setLocalStorage } from '../../Helper/LocalStorage'
import { setCookie } from '../../Helper/Cokkies'
import axios from 'axios'
import { Navigate } from 'react-router-dom';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { useState, useContext } from 'react';
import { apiContext, ApiToggle } from './ApiToggle';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© All right reserved 2023. '}
      <Link color="inherit" className='main-text' target={"_"} href="https://icsoln.com/">
        ICS
      </Link>
    </Typography>
  );
}

const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `rgb(0 255 179) 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function LoginPage() {
  const [DataForm, setDataForm] = useState({email:"", password:"", rememberLogin: false})
  const [passError, setPassError] = useState({error: false, message:""})
  const [emailError, setEmailError] = useState({error: false, message:""})
  const [isLogedin, setIslogedin] = useState(false)
  const [loading, setLoading] = useState(false)
  const api = useContext(apiContext)

  React.useEffect(() => {
    document.title = 'Login | ICS';
  }, [])

  if(isLogedin){
    return <Navigate replace to="/projects-list" />;
  }

  function FacebookCircularProgress(props) {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={20}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => (theme.palette.mode === 'light' ? '#575859' : '#308fe8'),
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={20}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }

  const handleSubmit = () => {
    setLoading(true)
    setEmailError({ ...emailError, error: false, message : "" })
    setPassError({ ...passError, error: false, message : "" })
    console.log("Sending request to "+api.url);
        axios.post(api.url + '/login', DataForm)
      .then(function (response) {
        // console.log(response.data);
        setLoading(false)
        setLocalStorage('user', { name: response.data.name, id: response.data.id, email: response.data.email, role: response.data.role })
        setCookie('token', response.data.token)
        setIslogedin(true)
      })
      .catch(function (error) {
        // handle error
        if(error.response.data.message.includes("password")){
          setPassError({ ...passError, error: true, message : "Please check your password" })
        }else{
          setEmailError({ ...emailError, error: true, message : "Please check your Email" })
        }
        console.log(error);
        setLoading(false)
      })
  };

  const changeHandler = (e) => {
    setDataForm({ ...DataForm, [e.target.name]: e.target.value })
  }

  return (
    <Grid container component="main" sx={{ height: '100vh', background:"white" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={6}
        md={7}
        sx={{
          backgroundImage: 'url(/vector.webp)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? "white" : t.palette.grey[900],
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={6} md={5} component={Paper} elevation={6} square>
        <ApiToggle onChange={api.toggleUrl}/>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img style={{ width: "150px" }} src="/icslogo.png" alt="" />
          {/* <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            </Avatar> */}
          <Typography component="h1" style={{ marginTop: "25px", letterSpacing: "5px" }} variant="h5">
            SIGN IN
          </Typography>
          <Box sx={{ mt: 1 }}>
            <RedditTextField
              required
              fullWidth
              name="email"
              label="Email Address"
              type="email"
              onChange={(e) => changeHandler(e)}
              id="email"
              variant="filled"
              style={{ marginTop: 11 }}
              error={emailError.error}
              helperText={emailError.error? emailError.message :""}
            />
            <RedditTextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              onChange={(e) => changeHandler(e)}
              id="password"
              variant="filled"
              style={{ marginTop: 11 }}
              error={passError.error}
              helperText={passError.error? passError.message :""}
            />
            <FormControlLabel
              control={<Checkbox value="remember" onChange={(e)=> setDataForm({...DataForm, rememberLogin: e.target.checked})} color="primary" />}
              label="Remember me"
            />
            <Button
              fullWidth
              variant="contained"
              disabled={loading}
              className='ics-btn'
              sx={{ mt: 3, mb: 2 }}
              onClick={(e) => { handleSubmit()}}
            >Sign In {" "} {loading? <FacebookCircularProgress /> :"" }
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="./forgotpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}