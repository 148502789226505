import { configureStore } from '@reduxjs/toolkit'
import pageIndexReducer from '../Redux/pageIndex'
import CompanyUserReducer from '../Redux/CompanyUser'
import LazyLoadReducer from '../Redux/LazyLoad'
export const store = configureStore({
  reducer: {
    pageIndex: pageIndexReducer,
    CompanyUser: CompanyUserReducer,
    LazyLoad: LazyLoadReducer,
  },
})