import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import { api } from '../../Helper/Data'
import { styled } from '@mui/material/styles';
import axios from 'axios'
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { useState, useContext } from 'react';
import { apiContext } from './ApiToggle';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© All right reserved 2023. '}
      <Link color="inherit" className='main-text' target={"_"} href="https://icsoln.com/">
        ICS
      </Link>
    </Typography>
  );
}

const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `rgb(0 255 179) 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function PasswordResetPage() {
  const [formMail, setFormMail] = useState({mail:""})
  const [emailError, setEmailError] = useState({err: false, message:""})
  const [OTPSent, setOTPSent] = useState({isit: false, hash:""})
  const [OTPValidated, setOTPValidated] = useState({isit: false, err: ""})
  const [passwordError, setPasswordError] = useState(false)
  const [loading, setLoading] = useState(false)
  const api = useContext(apiContext)

  React.useEffect(() => {
    document.title = 'Login | ICS';
  }, [])

  function FacebookCircularProgress(props) {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={20}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => (theme.palette.mode === 'light' ? '#575859' : '#308fe8'),
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={20}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }

  const handleSubmit = () => {
    setLoading(true);
    setEmailError({ ...emailError, err: false, message : "" })
      axios.post(api.url + '/reset', formMail)
      .then(function (response) {
        setOTPSent({isit: true,hash: response.data.otp_hash})
        setLoading(false)
      })
      .catch(function (error) {
        // handle error
        setEmailError({ ...emailError, err: true, message : "This email is not registered with us." });
        console.log(error);
        setLoading(false)
      })
  };

  const handleOTPSubmit = async () => {
    var data = document.getElementById('otp').value
    setLoading(true)
    axios.post(api.url + '/validate-otp', {otp:data, hash: OTPSent.hash})
      .then(function (res) {
        setOTPValidated({isit: true, err:""})
        setLoading(false)
      })
      .catch(function (err) {
        setOTPValidated({ isit: false, err : "Incorrect OTP" });
        setLoading(false)
      })
  }
  const handlePasswordSubmit = async (e) =>{
    if(document.getElementById("password").value === document.getElementById("confirm_password").value){
      setPasswordError(false)
      axios.post(api.url + '/update-password', {email_id: formMail.mail, newPassword: document.getElementById("password").value})
      .then(function (res) {
        alert("Password updated successfully")
        window.location.href = "/login";
      })
      .catch(function (err) {
        console.log(err)
        alert("There was an internal error. Please try again later.")
      })
    } else{
      setPasswordError(true)
    }
  }

  const changeHandler = (e) => {
    setFormMail({mail: e.target.value});
  }

  return (
    <Grid container component="main" sx={{ height: '100vh', background:"white" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={6}
        md={7}
        sx={{
          backgroundImage: 'url(/vector.webp)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? "white" : t.palette.grey[900],
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={6} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img style={{ width: "150px" }} src="/icslogo.png" alt="" />
          {/* <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            </Avatar> */}
          <Typography component="h1" style={{ marginTop: "25px", letterSpacing: "5px" }} variant="h5">
            RESET PASSWORD
          </Typography>
          {/* for email */}
          {!OTPSent.isit && !OTPValidated.isit && <Box sx={{ mt: 3 }}>
            <Box sx={{px: 3, textAlign: "center"}}>Enter your registered email address to recieve a recovery mail</Box>
            <RedditTextField required
              fullWidth
              name="email"
              label="Email Address"
              type="email"
              onChange={(e) => changeHandler(e)}
              id="email"
              variant="filled"
              style={{ marginTop: 11 }}
              error={emailError.err}
              helperText={emailError.err? emailError.message :""}
              placeholder={"Email"}
            />
            <Button
              fullWidth
              variant="contained"
              disabled={loading}
              className='ics-btn'
              sx={{ mt: 3, mb: 2 }}
              onClick={(e) => { handleSubmit()}}
            >Recover {" "} {loading? <FacebookCircularProgress /> :"" }
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="./login" variant="body2">
                  Log In
                </Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>}
          {/* for OTP */}
          {OTPSent.isit && !OTPValidated.isit && <Box sx={{ mt: 3 }}>
            <Box sx={{px: 3, textAlign: "center"}}>Check your inbox for a 6-digit verification code.</Box>
            <RedditTextField required
              fullWidth
              name="otp"
              label="Verification Code"
              type="text"
              id="otp"
              variant="filled"
              style={{ marginTop: 11 }}
              error={!OTPValidated.isit}
              helperText={!OTPValidated.isit? OTPValidated.err :""}
            />
            <Button
              fullWidth
              variant="contained"
              disabled={loading}
              className='ics-btn'
              sx={{ mt: 3, mb: 2 }}
              onClick={(e) => { handleOTPSubmit()}}
            >Verify {" "} {loading? <FacebookCircularProgress /> :"" }
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="./login" variant="body2">
                  Log In
                </Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>}
          {/* for password */}
          {OTPSent.isit && OTPValidated.isit && <Box sx={{ mt: 3 }}>
            <Box sx={{px: 3, textAlign: "center"}}>Enter your new password.</Box>
            <RedditTextField required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              variant="filled"
              style={{ marginTop: 11 }}
              error={passwordError}
            />
            <RedditTextField required
              fullWidth
              name="confirm_password"
              label="Confirm Password"
              type="password"
              id="confirm_password"
              variant="filled"
              style={{ marginTop: 11 }}
              error={passwordError}
              helperText={passwordError? "Entered Passwords do not match" :""}
            />
            <Button
              fullWidth
              variant="contained"
              disabled={loading}
              className='ics-btn'
              sx={{ mt: 3, mb: 2 }}
              onClick={(e) => { handlePasswordSubmit()}}
            >Submit {" "} {loading? <FacebookCircularProgress /> :"" }
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="./login" variant="body2">
                  Log In
                </Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>}
        </Box>
      </Grid>
    </Grid>
  );
}