import { Alert, Box, Button, Card, CardContent, CardHeader, Collapse, Container, Dialog, DialogActions, DialogTitle, IconButton, Stack, Tooltip } from '@mui/material'
import TextField from '@mui/material/TextField';
import React, { useEffect, useState, useContext } from 'react'
// import { api } from '../../Helper/Data'
import axios from 'axios';
import moment from "moment";
import style from './style.module.css'
import { getCookie } from '../../Helper/Cokkies'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DataGrid, GridToolbarQuickFilter, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom'
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux'
import { setIndexValue } from '../../Redux/pageIndex';
import { apiContext } from '../Login/ApiToggle';


export default function ProjectList() {
  const token = getCookie('token')
  const [pageSize, setPageSize] = useState(15);
  const [dataList, setDataList] = useState([]);
  const [resetTable, setResetTable] = useState(true);
  const [isAlert, setIsAlert] = useState({ status: false, severity: "success", message: "" })
  const [dataLoad, setDataLoad] = useState(true);
  const [dialogData, setDialogData] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch()
  const [sortModel, setSortModel] = useState([{ field: 'rating', sort: 'desc' }]);
  const api = useContext(apiContext)

  useEffect(() => {
    document.title = 'Project List | ICS';
    dispatch(setIndexValue(1))
  }, [dispatch])

  if (resetTable) {
    axios.post(api.url + '/projects-list', { id: "id" }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        response.data.client.forEach((item, index) => {
          item.id = index + 1;
        });
        setIsAlert({ status: false, severity: "", message: "" })
        setDataList(response.data.client)
        setStartDate(null)
        setEndDate(null)
        clearSearch()
        setResetTable(false)
        setDataLoad(false)
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  const clearSearch = () =>{        //works......but very crude, so please change it if you add more buttons to the UI
    const buttons = document.getElementsByTagName("button");
    buttons[4].click();
  }

  const deleteAction = (id) => {
    setIsAlert({ ...isAlert, status: false, severity: "", message: "" })
    axios.post(api.url + '/delete-project', { id: id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        setDataList(dataList.filter((el) => { return el._id !== id }))
        setIsAlert({ ...isAlert, status: true, severity: "success", message: <>Project Deleted Successful!</> })
        setDialogOpen(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ padding: "15px", borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "space-evenly", alignItems: "center", flexWrap: "wrap" }} >
          <Tooltip title="Print/Download">
            <GridToolbarExport />
          </Tooltip>
          <div style={{ margin: "10px 5px" }}>
            <span className='main-text'> <CalendarMonthIcon /> </span>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                className={style.datePicker}
                inputFormat="DD/MM/YYYY"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue._d)
                  setEndDate(endDate !== null ? endDate : new Date())
                  searchAction(newValue._d, endDate !== null ? endDate : new Date())
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <span className='main-text'> To </span>
              <MobileDatePicker
                className={style.datePicker}
                value={endDate}
                inputFormat="DD/MM/YYYY"
                onChange={(newValue) => {
                  setStartDate(startDate !== null ? startDate : new Date())
                  setEndDate(newValue._d)
                  searchAction(startDate !== null ? startDate : new Date(), newValue._d)
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

          </div>
          <GridToolbarQuickFilter />
          <div>
            <Tooltip title="Reset List">
              <Button onClick={(e) => setResetTable(true)}>Reset</Button>
            </Tooltip>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  const searchAction = (start, end) => {
    const date1 = new Date(moment(start).format('MM/DD/YYYY'));
    const date2 = new Date(moment(end).format('MM/DD/YYYY'));

    if (date2 - date1 < 0) {
      setIsAlert({ ...isAlert, status: true, severity: "error", message: <>Start date should be lower than end date! </> })
    } else {
      setIsAlert({ status: false, severity: "", message: "" })
      setDataLoad(true)
      axios.post(api.url + '/date-filter', { startDate: start, endDate: end }, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          response.data.client.forEach((item, index) => {
            item.id = index + 1;
          });
          setDataList(response.data.client)
          setDataLoad(false)
        })
        .catch(function (error) {
          console.log(error);
          setDataLoad(false)
        })
    }
  }

  const columns = [
    {
      field: 'id', width: 80, renderHeader: () => (
        <strong>S.No</strong>
      ),
    },
    {
      field: 'firm', width: 250, renderHeader: () => (
        <strong>
          Firm
        </strong>
      ),
    },
    {
      field: 'projectName', width: 200, renderHeader: () => (
        <strong>
          Project Name
        </strong>
      ),
    },
    {
      field: 'projectType', width: 150,
      renderHeader: () => (
        <strong>
          Project Type
        </strong>
      ),
    },
    {
      field: 'reminderDate', width: 130,
      renderHeader: () => (
        <strong>
          Reminder Date
        </strong>
      ),
      valueGetter: (params) => moment(params.row.reminderDate).format("YYYY-MM-DD")
    },
    {
      field: 'validity', width: 150,
      renderHeader: () => (
        <strong>
          Validity
        </strong>
      ),
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Chip color={params.row.validity === "Yearly" ? "info" : "success"} avatar={<Avatar>{Array.from(params.row.validity)[0]}</Avatar>} label={params.row.validity} />
          </Stack>
        );
      },
    },
    {
      field: '_id', width: 120,
      sortable: false,
      renderHeader: () => (
        <strong>
          Action
        </strong>
      ),
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Link state={{ params: params.row }} to={"/edit-client"}>
              <IconButton style={{ color: "dimgray" }}>
                <EditIcon />
              </IconButton>
            </Link>
            <IconButton style={{ color: "red" }} onClick={(e) => {setDialogOpen(true); setDialogData(params.row)}}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    }
  ];


  return (
    <div>
      <Container maxWidth='lg'>
        <Card style={{ height: "100%", minHeight: "80vh" }}>
          <CardHeader style={{ textAlign: "center", color: "white" }} className={"main-bg"}
            title="Projects List"
          />
          <CardContent style={{ paddingBottom: "10px" }}>
            <Box sx={{ height: "100vh", width: '100%' }}>
              <DataGrid
                getRowId={row => row.id}
                rows={dataList}
                columns={columns}
                components={{
                  Toolbar: CustomToolbar,
                }}
                disableSelectionOnClick
                disableColumnSelector
                pagination
                loading={dataLoad}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 20]}
                sortModel={sortModel}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              />
            </Box>
            <Collapse in={isAlert.status}>
              <Alert
                severity={isAlert.severity}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setIsAlert({ ...isAlert, status: false });
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {isAlert.message}
              </Alert>
            </Collapse>
          </CardContent>
          <DialogComponent open={dialogOpen} closeDialog={() => setDialogOpen(false)} deleteProps={deleteAction} data={dialogData} />
        </Card>
      </Container>
    </div>
  )
}


const DialogComponent = (props) => {
  return <Dialog
    open={props.open}
    onClose={(e) => props.closeDialog(e)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Want to delete project <strong>{props.data.projectName}</strong> of firm : <strong> {props.data.firm} </strong> ???
    </DialogTitle>
    <DialogActions>
      <Button onClick={(e) => props.closeDialog(e)}>Don't</Button>
      <Button onClick={(e) => props.deleteProps(props.data._id)} autoFocus>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
}