import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from '@mui/icons-material/Logout';
import { removeLocalStorage } from '../../Helper/LocalStorage'
import { removeCookie } from '../../Helper/Cokkies';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddIcon from '@mui/icons-material/Add';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Zoom from '@mui/material/Zoom';

function Navbar() {
    const theme = useTheme();
    const pageIndex = useSelector((state) => state.pageIndex.value);

    const fabs = [
        {
            key: 'addP',
            color: 'primary',
            icon: <AddIcon />,
            label: 'Add Project',
            link: '/add-project',
            value: 0
        },
        {
            key: 'viewL',
            color: 'info',
            icon: <ListAltIcon />,
            label: 'View Project List',
            link: '/projects-list',
            value: 1
        },
        {
            key: 'addF',
            color: 'primary',
            icon: <DomainAddIcon />,
            label: 'Add Firm',
            link: '/add-firm',
            value: 2
        },  
    ];

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const userLogout = (e) => {
        removeLocalStorage("user");
        removeCookie("token");
        window.location.replace("/login")
    };

    return (
        <>
            <AppBar position="static" style={{ backgroundColor: "transparent", boxShadow: "none" }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 1,
                                mb: 1,
                                display: { md: 'flex' },
                                textDecoration: 'none',
                            }}
                        >
                            <img src="/icslogo.png" alt="" style={{ height: "80px", filter: "saturate(0.5)" }} />
                        </Typography>

                        <Box sx={{ flexGrow: 1 }}>

                        </Box>


                        <Box sx={{ flexGrow: 0, margin: "5px" }}>
                            <Tooltip title="Logout">
                                <IconButton onClick={(e) => userLogout(e)} color="error" size="large" >
                                    <LogoutIcon sx={{ fontSize: 30 }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <div style={{
            position: "fixed",
            bottom: 16,
            right: 16,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            }}>
            {fabs.map((fab, index) => (
                <Zoom
                    key={fab.value}
                    in={true}
                    timeout={transitionDuration}
                    style={{
                        display : (pageIndex !== index ? "block":"none"), // still leaves the tool-tip intact
                        transitionDelay: `${pageIndex !== index ? transitionDuration.exit : 0}ms`,
                    }}
                    unmountOnExit
                >
                    <Fab aria-label={fab.label} color={fab.color}>
                        <Tooltip title={fab.label}>
                            <Link to={fab.link} style={{ textDecoration: "none", color: "initial" }}>
                                <IconButton size="large" >
                                    {fab.icon}
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </Fab>
                </Zoom>
            ))}
            </div>
        </>
    );
}
export default Navbar;