import React, { useEffect, useState, useContext } from 'react'
import { Alert, Box, Button, Card, CardContent, CardHeader, Collapse, Container, Grid, IconButton, Typography } from '@mui/material'
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
// import { api } from '../../Helper/Data'
import axios from 'axios';
import { getCookie } from '../../Helper/Cokkies';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { formValidation } from '../../Components/Utils'
import { useDispatch } from 'react-redux'
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { setIndexValue } from '../../Redux/pageIndex';
import { apiContext } from '../Login/ApiToggle';

const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));


function AddFirm() {
  const [DataForm, setDataForm] = useState({ firm: "" })
  const [isAlert, setIsAlert] = useState({ status: false, severity: "success", message: "" })
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [firmList, setFirmList] = useState(["ICS", "AT",])
  const token = getCookie('token')
  const dispatch = useDispatch()
  const api = useContext(apiContext)

  const resetFirms = () => {
    axios.post(api.url + '/firms-list', { id: "id" }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        const firms = response.data.f.map(item => item.firm);
        setFirmList(firms);
      })
  }

  useEffect(() => {
    if (resetFirms) {
      resetFirms();
    }
  }, [firmList]);

  useEffect(() => {
    document.title = 'Add Firm | ICS';
    dispatch(setIndexValue(2))
  }, [dispatch])

  const changeHandler = (e) => {
    setDataForm({ ...DataForm, [e.target.name]: e.target.value })
  }

  const handleDelete = (e) => {
    setIsAlert({ ...isAlert, status: false, severity: "", message: "" })
    var reminder_count = 0;
    axios.post(api.url + '/find-firm-reminders', { firm: e }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        reminder_count = response.data.count;
        if (reminder_count === 0) {
          axios.post(api.url + '/remove-firm', { firm: e }, { headers: { authorization: "Bearer " + token } })
            .then(function (response) {
              console.log(response.data);
              setIsAlert({ ...isAlert, status: true, severity: "success", message: <>Firm removed Successfully! </> })
              resetFirms();
            })
            .catch(function (error) {
              setIsAlert({ ...isAlert, status: true, severity: "error", message: <>Something went wrong!</> })
            })
        }
        else {
          setIsAlert({ ...isAlert, status: true, severity: "error", message: <>Given firm has reminders attached to it!</> })
        }
      })
  }

  const handleSubmit = () => {
    setIsAlert({ ...isAlert, status: false, severity: "", message: "" })
    if (formValidation([DataForm.firm])) {
      setIsSubmiting(true)
      axios.post(api.url + '/add-firm', DataForm, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          // console.log(response.data);
          setIsSubmiting(false)
          setIsAlert({ ...isAlert, status: true, severity: "success", message: <>New firm has been add successfull ! <Link to={"/add-project"} style={{ textDecoration: "none" }}>Add a Project</Link> </> })
          setDataForm({ firm: "" })
          resetFirms();
        })
        .catch(function (error) {
          alert("blank")
          // handle error
          console.log(error);
        })
    } else {
      setIsAlert({ ...isAlert, status: true, severity: "error", message: <>All fields are required!</> })
    }
  }

  function FacebookCircularProgress(props) {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={20}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => (theme.palette.mode === 'light' ? '#575859' : '#308fe8'),
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={20}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }

  return (
    <div>
      <Container maxWidth='lg'>
        <Card style={{ height: "100%", minHeight: "80vh" }}>
          <CardHeader style={{ textAlign: "center", color: "white" }} className={"main-bg"}
            title="Add Firm"
          />
          <CardContent>
            <Grid container spacing={2} style={{ justifyContent: "center" }}>

              <Grid item md={6} sm={12} xs={12}>
                <RedditTextField
                  required
                  size='small'
                  fullWidth
                  name="firm"
                  label="Firm Name"
                  value={DataForm.firm}
                  onChange={(e) => changeHandler(e)}
                  id="firmName"
                  variant="filled"
                  style={{ marginTop: 11 }} />
              </Grid>

              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  fullWidth
                  style={{ maxWidth: "400px" }}
                  variant="contained"
                  disabled={isSubmiting}
                  className='ics-btn'
                  onClick={(e) => handleSubmit()}
                  endIcon={isSubmiting ? "" : <AddIcon />}
                >
                  {isSubmiting ? <>Adding {" "} <FacebookCircularProgress /></> : "Add"}
                </Button>
              </Grid>
              <Grid item xs={6} sx={{ margin: "auto", overflow: "normal", mt: 5, }}>
                <Box sx={{ mb: 2, textAlign: "center" }}> {/* Adjust 'mb' for desired spacing */}
                  <Typography variant="h6" component="div">
                    Existing Firms
                  </Typography>
                </Box>
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                  {firmList.map((firm) => (
                    <Chip label={firm} variant="outlined" onDelete={() => { handleDelete(firm) }} color="primary" sx={{ color: "black" }} />
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={isAlert.status}>
                  <Alert
                    severity={isAlert.severity}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsAlert({ ...isAlert, status: false });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    {isAlert.message}
                  </Alert>
                </Collapse>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}

export default AddFirm