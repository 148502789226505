import { createSlice } from '@reduxjs/toolkit'


export const pageIndexSlice = createSlice({
  name: 'pageIndex',
  initialState : {
    value: 1,
  },
  reducers: {
    indexValue: (state, action) => {
      state.value = action.payload
    },
    setIndexValue: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { indexValue, setIndexValue } = pageIndexSlice.actions

export default pageIndexSlice.reducer