import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {Helmet} from "react-helmet";
import './App.css';
import AddClient from "./Modules/AddClient/AddClient";
import AddFirm from "./Modules/AddClient/AddFirm";
import EditClient from "./Modules/AddClient/EditClient";
import HomePage from "./Modules/Home/HomePage";
import LoginPage from "./Modules/Login/LoginPage";
import ProjectList from "./Modules/ProjectList/ProjectList";
import PasswordResetPage  from "./Modules/Login/PasswordResetPage";
import { ApiContextWrapper } from "./Modules/Login/ApiToggle";


function App() {  
  return (
    <ApiContextWrapper>
      <BrowserRouter>
      {/* <Helmet>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      </Helmet>  code to bypass mandatory https on api end*/} 
        <Routes>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/forgotpassword" element={<PasswordResetPage />}></Route>
          {/* <Route path="/logout" element={<Logout />}></Route> */}
          <Route path="/" element={<HomePage />} >
            <Route path="/" element={<ProjectList />}></Route>
            <Route path="/add-project" element={<AddClient />}></Route>
            <Route path="/add-firm" element={<AddFirm />}></Route>
            <Route path="/projects-list" element={<ProjectList />}></Route>
            <Route path="/edit-client" element={<EditClient />}></Route>
          </Route>
          <Route path="/*" element={<Navigate replace to="404" />} />
          {/* <Route path="/404" element={<Notfound />}></Route> */}
        </Routes>
      </BrowserRouter>
    </ApiContextWrapper>
  );
}

export default App;
