import React, { useEffect, useState, useContext } from 'react'
import { Alert, Box, Button, Card, CardContent, CardHeader, Collapse, Container, Grid, IconButton, MenuItem } from '@mui/material'
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { api } from '../../Helper/Data'
import axios from 'axios';
import { getCookie } from '../../Helper/Cokkies'
import style from './style.module.css'
import { formValidation } from '../../Components/Utils'
import { useDispatch} from 'react-redux'
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { setIndexValue } from '../../Redux/pageIndex';
import moment from "moment";
import { apiContext } from '../Login/ApiToggle';

const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));


function AddClient() {
  const [DataForm, setDataForm] = useState({ firm: "", projectName: "", projectType: "", reminderDate: null, validity: "" })
  const [firmList, setFirmList] = useState();
  const [otherType, setOtherType] = useState(false)
  const [isAlert, setIsAlert] = useState({ status: false, severity: "success", message: "" })
  const [isSubmiting, setIsSubmiting] = useState(false)
  const token = getCookie('token')
  const dispatch = useDispatch()
  const api = useContext(apiContext)

  useEffect(() => {
    axios.post(api.url + '/firms-list', { id: "id" }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        setFirmList(response.data.f);
      })
  }, [])

  useEffect(() => {
    document.title = 'Add Project | ICS';
    dispatch(setIndexValue(0))
  }, [dispatch])

  const changeHandler = (e) => {
    setDataForm({ ...DataForm, [e.target.name]: e.target.value })
  }
  const selectType = (e) => {
    e.target.value === 'Other' ? setOtherType(true) : setOtherType(false)
    setDataForm({ ...DataForm, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {
    setIsAlert({ ...isAlert, status: false, severity: "", message: "" })
    if (formValidation([DataForm.firm, DataForm.projectName, DataForm.projectType, DataForm.reminderDate, DataForm.validity])) {
      setIsSubmiting(true)
      axios.post(api.url + '/add-project', DataForm, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          // console.log(response.data);
          setIsSubmiting(false)
          setIsAlert({ ...isAlert, status: true, severity: "success", message: <>New project has been add successfull ! <Link to={"/projects-list"} style={{ textDecoration: "none" }}>Go to Project List</Link> </> })
          setDataForm({ firm: "", projectName: "", projectType: "", reminderDate: null, validity: "" })
        })
        .catch(function (error) {
          alert("blank")
          // handle error
          console.log(error);
        })
    } else {
      setIsAlert({ ...isAlert, status: true, severity: "error", message: <>All fields are required!</> })
    }
  }

  const selectDate =(date)=>{
    if(date != null){
      const cDate = new Date();
      const date2 = new Date(moment(date._d).format('MM/DD/YYYY'));
      if (date2 - cDate < 0) {
        setIsAlert({ ...isAlert, status: true, severity: "error", message: <>Date should be more than today! </> })
      } else {
        date2.setTime(date2.getTime()+(6 * 60 * 60 * 1000))
        setDataForm({ ...DataForm, reminderDate: date2 })
      }
    }
  }

  function FacebookCircularProgress(props) {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={20}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => (theme.palette.mode === 'light' ? '#575859' : '#308fe8'),
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={20}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }

  return (
    <div>
      <Container maxWidth='lg'>
        <Card style={{ height: "100%", minHeight: "80vh" }}>
          <CardHeader style={{ textAlign: "center", color: "white" }} className={"main-bg"}
            title="Add Project"
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <RedditTextField
                  required
                  select
                  size='small'
                  fullWidth
                  name="firm"
                  label="Firm"
                  value={DataForm.firm}
                  onChange={(e) => changeHandler(e)}
                  id="firm"
                  variant="filled"
                  style={{ marginTop: 11 }}>
                    <MenuItem key={""} value={""}>
                    Select
                  </MenuItem>
                 {firmList && firmList.map((item) => (
                    <MenuItem key={item.firm} value={item.firm}>
                      {item.firm}
                    </MenuItem>
                  ))}
                </RedditTextField>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <RedditTextField
                  required
                  size='small'
                  fullWidth
                  name="projectName"
                  label="Project Name"
                  value={DataForm.projectName}
                  onChange={(e) => changeHandler(e)}
                  id="projectName"
                  variant="filled"
                  style={{ marginTop: 11 }} />
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <RedditTextField
                  required
                  select
                  size='small'
                  fullWidth
                  name="projectType"
                  label="Project Type"
                  value={DataForm.projectType}
                  onChange={(e) => selectType(e)}
                  id="projectType"
                  variant="filled"
                  style={{ marginTop: 11 }}>
                  <MenuItem key={""} value={""}>
                    Select
                  </MenuItem>
                  <MenuItem key={"Hosting"} value={"Hosting"}>
                    Hosting
                  </MenuItem>
                  <MenuItem key={"Domain"} value={"Domain"}>
                    Domain
                  </MenuItem>
                  <MenuItem key={"AMP"} value={"AMP"}>
                    AMP
                  </MenuItem>
                  <MenuItem key={"Email"} value={"Email"}>
                    Email
                  </MenuItem>
                  <MenuItem key={"Birthday"} value={"Birthday"}>
                    Birthday
                  </MenuItem>
                  <MenuItem key={"Aniversary"} value={"Aniversary"}>
                    Aniversary
                  </MenuItem>
                  <MenuItem key={"Other"} value={"Other"}>
                    Other
                  </MenuItem>
                </RedditTextField>
              </Grid>

              {otherType ? <Grid item md={6} sm={12} xs={12}>
                <RedditTextField
                  required
                  size='small'
                  fullWidth
                  name="projectType"
                  label="Other Project Type"
                  value={DataForm.projectType}
                  onChange={(e) => changeHandler(e)}
                  id="projectType"
                  variant="filled"
                  style={{ marginTop: 11 }} />
              </Grid> : ""}

              <Grid item md={6} sm={12} xs={12}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    className={style.datePicker}
                    inputFormat="DD/MM/YYYY"
                    label="Date of Reminder"
                    value={DataForm.reminderDate}
                    onChange={(newValue) => {
                      selectDate(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <RedditTextField
                  required
                  select
                  size='small'
                  fullWidth
                  name="validity"
                  label="Validity"
                  value={DataForm.validity}
                  onChange={(e) => changeHandler(e)}
                  id="validity"
                  variant="filled"
                  style={{ marginTop: 11 }}>
                  <MenuItem key={""} value={""}>
                    Select
                  </MenuItem>
                  <MenuItem key={"Yearly"} value={"Yearly"}>
                    Yearly
                  </MenuItem>
                  <MenuItem key={"Monthly"} value={"Monthly"}>
                    Monthly
                  </MenuItem>
                </RedditTextField>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  fullWidth
                  style={{ maxWidth: "400px" }}
                  variant="contained"
                  disabled={isSubmiting}
                  className='ics-btn'
                  onClick={(e) => handleSubmit()}
                  endIcon={isSubmiting ? "" : <AddIcon />}
                >
                  {isSubmiting ? <>Adding {" "} <FacebookCircularProgress /></> : "Add"}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={isAlert.status}>
                  <Alert
                    severity={isAlert.severity}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsAlert({ ...isAlert, status: false });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    {isAlert.message}
                  </Alert>
                </Collapse>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}

export default AddClient