import React from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { createContext, useState } from 'react';

export const apiContext = createContext("https://ics-api-he5b.onrender.com");

export const ApiContextWrapper = ({children}) =>{
    const [url, setUrl] = useState("https://ics-api-he5b.onrender.com");

    const toggleUrl = ()=>{
        if(url =="https://ics-api-he5b.onrender.com"){
            setUrl("https://reminderapi.icsoln.com");
        }
        else {
            setUrl("https://ics-api-he5b.onrender.com");
        }
        console.log(url)
    }

    return(
        <apiContext.Provider value={{ url, toggleUrl }}>
            {children}
        </apiContext.Provider>
    )
}

export const ApiToggle = ({ label, ...otherProps }) => {
  return (
    <FormControlLabel
      control={<Switch {...otherProps}/>}
      label={
        <span style={{ display: 'flex', alignItems: 'center' }}>
            <ElectricBoltIcon sx={{color: "#FF0"}} />
        </span>
      }
      sx={{position: "absolute", right: "10px"}}
    />
  );
};
