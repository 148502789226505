import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import { getCookie } from '../../Helper/Cokkies'
import { clearLocalStorage } from '../../Helper/LocalStorage'

function HomePage() {

  let token = getCookie("token")
  if(token === undefined || !localStorage.getItem('user')){
    clearLocalStorage()
    return <Navigate to={'login'} />
  } 
  
  return (
    <div style={{height:"100vh"}}>
      <Navbar />
      <Outlet/>
    </div>
  )
}

export default HomePage